body {
  font-family: "Open Sans", sans-serif;
}

h2, h3,h5, .card-body>.card-title {
  font-family: "Lobster", cursive;
  text-align: center;
  color: #363535;
}
.nav-cont{
  background-color: #fff;
  margin-bottom: 10px;
  padding-top: 10px;
  color: #abc9da;
  font-weight: 300;
}
.Navbar light {
  background-color: #1d1d1d;
  font-weight: 700;
}
.nav-link{
  margin: 0px 6px;
  margin-left: 20px;
  font-size: 18px;
  color: rgb(93, 33, 33);
  font-weight: 300;
}

.navbar-text button{
  background-color: #ffffff;
  color: rgb(90, 90, 90);
  font-size: 15px;
  border: #ece0e0;
  font-weight: 300;
}
/* Breadcrumb*/
.breadcrumb{
  margin:0px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size:20px ;
  color: rgb(155, 156, 157);
  text-shadow: .09em .04em 0 rgb(199, 199, 199);
  justify-content: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ".";
  color: #dfdede;
  letter-spacing: 2px;
}
/* Product*/
.comment-1{
  margin: 0px 20px;
  text-align: center;
}
.product-1{
  margin-bottom: 150px;
}
.product-2{
  margin-bottom: 150px;
}
/* Footer*/
.site-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(0, 0, 0);
  color: white;
}
.footer-content{
  display: flex;
  align-content:flex-end;
  justify-content: end;
}
.btn-social-icon{
  align-content:flex-end;
  margin: 2px 2px;
  text-align: end;
  font-size: 14px;
}
.site-footer1{
  margin-top: 6px;
  font-size: 11px;
  text-align: center;
}
.about-teff{
  margin-top: 3rem;
}
/* Card*/
.card-title {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.card-img-overlay .card-title {
  color: white;
  font-weight: 600;
  background-color: rgba(161, 155, 151, 0.4);
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}
.card-text{
  font-weight: 500;
  font-size: 20px;
}
/* Recipes*/
.ingera-serve1{
  margin: 1px 0px;
}
.row-content1{
  margin-top: 15px;
  margin-bottom: 200px;
  padding: 2px;
}
/* Contact */
.contact-1{
  margin-bottom: 60px;
  background-color: #f8f8f8;
}

/* Animation*/
.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}
/* About */
.about-teff{
  margin-bottom: 80px;
}
.container1
{
	position: relative;
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.container1 .box
{
	position: relative;
	width: 100%;
	height: 400px;
	margin: 20px 0px 40px  0px;
	box-sizing: border-box;
	overflow: hidden;
}
.container1 .box .imgBx
{
	position: absolute;
	top: 0;
	left: 1;
	width: 100%;
	height: 100%;
	background: #002417;
	clip-path: circle(400px at center 100px);
	transition: 0.5s;
	transition-delay: 0.5s;
}
.container1 .box:hover .imgBx
{
	clip-path: circle(80px at center 100px);
	transition-delay: 0s;
}
.container1 .box .imgBx img
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.container1 .box .content
{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 55%;
	padding: 1px;
	box-sizing: border-box;
	text-align: center;
}
.container1 .box .content h2
{
	margin: 0;
	padding: 0;
}
.container1 .box .content a
{
	text-decoration: none;
	background: #002417;
	color: #fff;
	padding: 2px;
	display: inline-block;
}
.container1 .box .content h2,
.container1 .box .content p,
.container1 .box .content a
{
	opacity: 0;
	transition: 0.5s;
	transform: translateY(20px);
}
.container1 .box:hover .content h2
{
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0.5s
}
.container .box:hover .content p
{
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0.7s
}
.container1 .box:hover .content a
{
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0.9s
}
.meme--image{
	max-width: 100%;
	width: 100%;
	margin: 20px 0px 40px  0px;
}
.form button{
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left:20px;
}
